@import url('https://fonts.googleapis.com/css?family=Poppins');

.form-container {
    position: relative;
    left: 20%;
    width: 60%;
    height: 80vh;
}

.contact-form {
    position: relative;
    left: 10%;
    width: 80%;
}

label {
    color: #fff;
    text-align: left;
    font-size: 15px;
    margin: 0;
    padding: 0px;
}

input, textarea {
    width: 100%;
    line-height: 18px;
    padding: 10px 5px;
    margin-bottom: 12px;
    border: none;
    border-bottom: 1px solid #383c4c;;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
    border-radius: 5px;
}

.form-h3 {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    text-align: center;
    padding: 5px;
    font-style: italic;
    font-weight: 300;
    font-size: 24px;
}

.error-message {
    color: #383c4c;
    font-size: 15px;
    margin-top: -10px;
    margin-bottom: 10px;
  }

.error-border {
    border: 2px solid #96274b;
}

.submit {
    border: 2px solid #000;
    border-radius: 5px;
    margin-top: 20px;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
    background-color: #383c4c;
    cursor: pointer;
    font-weight: 200;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin: 20px 0 0 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.submit:hover {
    opacity: 0.6;
}


@media only screen and (max-width:700px){

    .form-container { 
        height: auto;
        left: 5%;
        width: 80%;
    }
    .contact-form {
        width: 100%;
        margin: 0;
    }

    .form-h3 {
        margin: 5px;
    }
}