@import url('https://fonts.googleapis.com/css?family=Poppins');

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin: 0px;
}

.imgwrapper {
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgdiv {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
}


.navbar {
    background-color: #383c4c;
    text-align: right;
    padding: 0px 0 0px 0;
    width: 100%;
    font-size: 14px;
}

.navbar ul {
    list-style-type: none;
    width: 100%;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}

.navbar a {
    color: white;
    text-decoration: none;
    padding: 15px;
    display: block;
    text-align: center;
}

.navbar a:hover{
    color: #99c0e2;
}

.navbar li{
    display: inline-block;
    padding-right: 20px;
}

.discover {
    font-family: 'Poppins', sans-serif;
    background-color: white;
    color: #383c4c;
    width: 100%;
    margin-top: 0px;
    padding: 5px 20px;
}

.ologo {
    height: 200px;
    width: 200px;
    padding: 0 20px 0 20px;
  }

.slogo {
    height: 125px;
    width: 325px;
    padding: 0 20px 0 20px;
}

.sep {
    font-family: 'Poppins', sans-serif;
    font-size: 100px;
    color: #fff;
}

@media only screen and (max-width:700px){
    .ologo {
        height: 125px;
        width: 125px;
    }
    .slogo {
        height: 65px;
        width: 165px;
    }
    .sep {
        font-size: 50px;
    }
}
  



