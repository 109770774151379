@import url('https://fonts.googleapis.com/css?family=Poppins');

.App {
  background-color: #3483c5;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.footer {
  background-color: #383c4c;
  height: 50px;
}

.footer-text {
  color: #fff;
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  padding: 10px;
  margin-top: 5px;
}


